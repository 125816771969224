var render = function render(_c,_vm){return _c(_vm.props.tag,_vm._g(_vm._b({tag:"component",staticClass:"text",class:[
		{
			'text--max-lines': _vm.props.maxLines || _vm.props.lines,
			'text--lines': _vm.props.lines,
		},
		_vm.data.staticClass,
		_vm.data.class,
		_vm.$options.classes(_vm.props),
	],style:(_vm.$options.style(_vm.props))},'component',_vm.data.attrs,false),_vm.listeners),[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }